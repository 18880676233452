import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import VLFTextField from "@/components/Input/TextField";
import VLFDatePicker from "@/components/Input/DatePicker";
import VLFSelectNew from "@/components/Input/SelectNew";

import {ClubAPI} from "@/api/club";
import {MatchAPI} from "@/api/match";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import {
	REQUIRED_FIELD,
	SUCCESSFUL_OPERATION,
	ERROR_OPERATION,
	matchTypesOptions,
} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";
import {useCategories} from "@/utils/hooks";

const HandleMatchPage = (props) => {
	const {edit, handleNotification} = props;

	const basePath = "/matches";

	const navigate = useNavigate();
	const {id} = useParams();

	const userId = useSelector((state) => state?.user?.userId);
	const clubId = useSelector((state) => state?.user?.clubId);
	const userName = useSelector((state) => state?.user?.userName);

	const {categories} = useCategories({
		setLoading: false,
		handleNotification,
	});
	const [clubs, setClubs] = useState([]);

	const getClubs = async () => {
		try {
			const response = await ClubAPI.list();
			setClubs(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [fields, setFields] = useState({
		startDate: null,
		category: null,
		homeTeam: null,
		awayTeam: null,
		matchType: null,
	});

	const getMatch = async () => {
		setLoading(true);
		try {
			const response = await MatchAPI.getById(id);
			response.startDate = response.startDate
				? new Date(response.startDate)
				: null;
			response.duration = moment
				.duration(moment(response?.endDate).diff(moment(response?.startDate)))
				.asMinutes();
			response.category = {
				value: response.playersCategoryId,
				label: response.playersCategory,
			};
			response.homeTeam = {
				value: response.homeTeamId,
				label: response.homeTeam,
			};
			response.awayTeam = {
				value: response.awayTeamId,
				label: response.awayTeam,
			};
			response.matchType = {
				value: response.matchType,
				label: response.matchType,
			};
			setFields(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (edit) {
			getMatch();
		}
		getClubs();
	}, [edit]);

	const getRequiredFields = () => {
		return edit
			? ["startDate", "duration", "homeTeam", "awayTeam", "matchType"]
			: [
					"startDate",
					"duration",
					"category",
					"homeTeam",
					"awayTeam",
					"matchType",
				];
	};

	const saveMatch = async () => {
		try {
			const errors = checkRequiredFields(getRequiredFields(), fields);
			setErrors(errors);

			if (!errors.length) {
				setLoading(true);

				let body = Object.assign(
					{},
					{
						...fields,
						endDate: moment(fields?.startDate)
							.add(fields?.duration, "minutes")
							.toISOString(),
						playersCategoryId: fields?.category.value,
						homeTeamId: fields?.homeTeam.value,
						awayTeamId: fields?.awayTeam.value,
						matchConvocations: [],
						clubId,
						userId,
						user: {userId, userName},
						matchType: fields.matchType.value,
						fieldName: fields.fieldName,
					},
				);

				delete body.category;
				delete body.homeTeam;
				delete body.awayTeam;

				const response = edit
					? await MatchAPI.update(id, body)
					: await MatchAPI.create(body);

				setTimeout(() => {
					navigate(`${basePath}/${response?.id || id}`);
					setLoading(false);
					handleNotification(SUCCESSFUL_OPERATION, "success");
				}, 1000);
			}
		} catch (error) {
			setLoading(false);
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	const createTeam = async (name, fieldKey) => {
		try {
			const response = await ClubAPI.create({name});
			setFields({
				...fields,
				[fieldKey]: {value: response.id, label: response.name},
			});
			getClubs();
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		saveMatch();
	};

	const goBack = () => {
		if (edit) {
			navigate(basePath + "/" + id);
		} else {
			navigate(-1);
		}
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: goBack,
			color: "info",
		},
		{
			icon: <SaveIcon />,
			title: "Salva",
			onClick: handleSubmit,
			disabled: checkRequiredFields(getRequiredFields(), fields).length
				? true
				: false,
		},
	];

	const breadcrumbsElements = [{label: "Partite", path: basePath}];
	if (edit) {
		breadcrumbsElements.push(
			{label: "/"},
			{
				label: fields
					? (fields.homeTeam?.label || "") +
						" - " +
						(fields.awayTeam?.label || "")
					: "",
				path: basePath + "/" + id,
			},
		);
	}

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni principali"
							content={
								<div className="row">
									<div className="col-sm-12 col-md-6">
										<VLFDatePicker
											type="datetime"
											label="Data inizio"
											value={fields.startDate}
											onChange={(value) => {
												setErrors([]);
												setFields({...fields, startDate: value});
											}}
											required
											helperText={
												errors.indexOf("startDate") !== -1 ? REQUIRED_FIELD : ""
											}
											error={errors.indexOf("startDate") !== -1}
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFTextField
											value={fields["duration"] || ""}
											onChange={(e) => {
												setErrors([]);
												setFields({...fields, duration: e.target.value});
											}}
											required
											helperText={
												errors.indexOf("duration") !== -1 ? REQUIRED_FIELD : ""
											}
											label="Durata (minuti)"
											type="number"
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFSelectNew
											options={categories.map((c) => {
												return {
													value: c.id,
													label: c.name,
												};
											})}
											value={edit ? fields.playersCategory : fields.category}
											onChange={(newValue) => {
												setErrors([]);
												setFields({...fields, category: newValue});
											}}
											sortOrder="descend"
											label="Categoria"
											required
											editable={!edit}
											helperText={
												errors.indexOf("category") !== -1 ? REQUIRED_FIELD : ""
											}
											error={errors.indexOf("category") !== -1}
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFTextField
											value={fields["round"] || ""}
											onChange={(e) => {
												setFields({...fields, round: e.target.value});
											}}
											label="Turno"
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFSelectNew
											options={clubs.map((c) => {
												return {
													value: c.id,
													label: c.name,
												};
											})}
											value={fields.homeTeam}
											onChange={(newValue) => {
												setErrors([]);
												setFields({...fields, homeTeam: newValue});
											}}
											label="Squadra casa"
											onCreateOption={(value) => createTeam(value, "homeTeam")}
											required
											helperText={
												errors.indexOf("homeTeam") !== -1 ? REQUIRED_FIELD : ""
											}
											error={errors.indexOf("homeTeam") !== -1}
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFSelectNew
											options={clubs.map((c) => {
												return {
													value: c.id,
													label: c.name,
												};
											})}
											value={fields.awayTeam}
											onChange={(newValue) => {
												setErrors([]);
												setFields({...fields, awayTeam: newValue});
											}}
											label="Squadra ospite"
											onCreateOption={(value) => createTeam(value, "awayTeam")}
											required
											helperText={
												errors.indexOf("awayTeam") !== -1 ? REQUIRED_FIELD : ""
											}
											error={errors.indexOf("awayTeam") !== -1}
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFTextField
											value={fields["results"] || ""}
											onChange={(e) => {
												setFields({...fields, results: e.target.value});
											}}
											label="Risultato"
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFSelectNew
											options={matchTypesOptions}
											value={fields.matchType}
											onChange={(newValue) => {
												setErrors([]);
												setFields({
													...fields,
													matchType: newValue,
												});
											}}
											label="Tipologia"
											required
											helperText={
												errors.indexOf("matchType") !== -1 ? REQUIRED_FIELD : ""
											}
											error={errors.indexOf("matchType") !== -1}
											editable={edit}
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFTextField
											value={fields.fieldName}
											onChange={(e) => {
												setFields({...fields, fieldName: e.target.value});
											}}
											label="Stadio"
											size="small"
										/>
									</div>
									<div className="col-sm-12 col-md-6">
										<VLFTextField
											value={fields["description"] || ""}
											onChange={(e) => {
												setFields({...fields, description: e.target.value});
											}}
											label="Descrizione"
											size="small"
											multiline
											minRows={3}
											maxRows={3}
										/>
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default HandleMatchPage;
