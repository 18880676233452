import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import NavigationBox from "@/components/NavigationBox";
import EmptyData from "@/components/EmptyData";
import VLFCard from "@/components/Card";

import {PlayerAPI} from "@/api/player";

import {
	useCategories,
	useClubs,
	useMatches,
	useReports,
	useTrainings,
	useUsers,
} from "@/utils/hooks";
import {ERROR_OPERATION} from "@/utils/constants";

import "./index.scss";

const today = moment().startOf("day");

function HomePage(props) {
	const {handleNotification} = props;
	const navigate = useNavigate();

	const playerId = useSelector((state) => state?.user?.playerId);
	const userRole = useSelector((state) => state?.user?.roleName);
	const selectedMenuItem = useSelector((state) => state?.selectedMenuItem);

	const [dashboardData, setDashboardData] = useState(false);
	const [playerData, setPlayerData] = useState();

	const {categories} = useCategories({setLoading: false, handleNotification});
	const {clubs} = useClubs({setLoading: false, handleNotification});
	const {matches} = useMatches({setLoading: false, handleNotification});
	const {reports} = useReports({setLoading: false, handleNotification});
	const {trainings} = useTrainings({
		setLoading: false,
		handleNotification: undefined,
		filters: {startDate: moment().subtract(1, "days").format("YYYY-MM-DD")},
	});
	const {users} = useUsers({setLoading: false, handleNotification});

	const [nextTrainings, setNextTrainings] = useState([]);
	const [nextMatches, setNextMatches] = useState([]);
	const [lastMatchesResults, setLastMatchesResults] = useState([]);

	const managerData = trainings && matches;
	const scoutData = reports;
	const settingsData = categories && clubs && users;

	const filterByCategory = (items, categoryKey, filterCallback) => {
		const categories = items
			.sort((a, b) => (a.playersCategory < b.playersCategory ? 1 : -1))
			.reduce((acc, item) => {
				const categoryId = item[categoryKey];
				if (!acc[categoryId]) acc[categoryId] = [];
				acc[categoryId].push(item);
				return acc;
			}, {});

		return Object.keys(categories).map((categoryId) => ({
			[categoryKey]: categoryId,
			items: categories[categoryId].filter(filterCallback),
		}));
	};

	const filterMatchesOrTrainings = (items, dateKey, userCategoryId) => {
		const isSameOrAfter = (item) =>
			moment(item[dateKey]).isSameOrAfter(today, "day");

		return items.filter((item) =>
			userCategoryId
				? item.playersCategoryId === userCategoryId && isSameOrAfter(item)
				: isSameOrAfter(item),
		);
	};

	const matchesFilter = () => {
		if (userRole === "Player") {
			if (playerData) {
				return filterMatchesOrTrainings(
					matches,
					"startDate",
					playerData?.playerCategoryId,
				);
			}
		}

		if (["Admin", "Coach", "Analyst"].includes(userRole)) {
			return filterByCategory(matches, "playersCategoryId", (match) =>
				moment(match.startDate).startOf("day").isSameOrAfter(today),
			);
		}

		return matches?.filter((el) =>
			moment(el.startDate).isSameOrAfter(today, "day"),
		);
	};

	const matchesResultsFilter = () => {
		const isEndDateBeforeOrEqualToday = (match) =>
			moment(match.endDate).isSameOrBefore(today, "hour");

		if (userRole === "Player") {
			getPlayer();
			return matches
				?.filter(
					(el) =>
						el.playersCategoryId === playerData?.playerCategoryId &&
						isEndDateBeforeOrEqualToday(el),
				)
				.reverse();
		}

		if (
			userRole === "Admin" ||
			userRole === "Coach" ||
			userRole === "Analyst"
		) {
			return filterByCategory(
				matches,
				"playersCategoryId",
				isEndDateBeforeOrEqualToday,
			);
		}

		return matches?.filter(isEndDateBeforeOrEqualToday);
	};

	const trainingsFilter = () => {
		const isSameOrAfter = (training) =>
			moment(training.startDate).startOf("day").isSameOrAfter(today);

		if (userRole === "Player") {
			getPlayer();
			return filterMatchesOrTrainings(
				trainings,
				"startDate",
				playerData?.playerCategoryId,
			);
		}

		if (["Admin", "Coach"].includes(userRole)) {
			return filterByCategory(trainings, "playersCategoryId", isSameOrAfter);
		}

		return trainings?.filter(isSameOrAfter);
	};

	const getPlayer = async () => {
		try {
			const response = await PlayerAPI.getById(playerId);
			response.hasStats =
				parseInt(response.playerCategoryName.split(" ")?.[1]) > 13;
			setPlayerData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		}
	};

	useEffect(() => {
		if (userRole === "Player") {
			getPlayer();
		}
	}, []);

	useEffect(() => {
		if (userRole !== "Player") {
			setNextMatches(matchesFilter);
			setNextTrainings(trainingsFilter);
			setLastMatchesResults(matchesResultsFilter);
		}
	}, [trainings, matches, userRole]);

	useEffect(() => {
		if (playerData && trainings?.length && matches?.length && !dashboardData) {
			setNextMatches(matchesFilter);
			setNextTrainings(trainingsFilter);
			setLastMatchesResults(matchesResultsFilter);
			setDashboardData(true);
		}
	}, [trainings, matches, playerData]);

	const loading = (userRole === "Scout" && !scoutData) || !managerData;

	const managerCoachNextMatchesEmpty = !nextMatches.filter(
		(nm) => nm.items?.length > 0,
	)?.length;
	const managerCoachNextTrainingsEmpty = !nextTrainings.filter(
		(nt) => nt.items?.length > 0,
	)?.length;
	const managerCoachLastMatchesEmpty = !lastMatchesResults.filter(
		(lm) => lm.items?.length > 0,
	)?.length;

	const [nextMatchesOpened, setNextMatchesOpened] = useState(true);
	const [matchesOpened, setMatchesOpened] = useState(true);
	const [nextTrainingsOpened, setNextTrainingsOpened] = useState(true);

	const getNextMatchesRender = (matches, firstElement = true) => {
		return (
			<>
				{matches.map((el) => {
					const currentPosition = firstElement ? 0 : el.items.length - 1;
					return el.items?.[currentPosition] ? (
						<NavigationBox
							key={el.items[currentPosition]?.id}
							dashboard
							label={
								el.items[currentPosition]?.homeTeam +
								" - " +
								el.items[currentPosition]?.awayTeam
							}
							value={moment(el.items[currentPosition]?.startDate).format(
								"DD/MM/YYYY HH:mm",
							)}
							info={el.items[currentPosition]?.playersCategory}
							dateInfo={moment(el.items[currentPosition]?.startDate).isSame(
								moment(),
								"day",
							)}
							imagePath={el.items[currentPosition]?.matchType}
							onClick={() =>
								navigate("/matches/" + el.items[currentPosition]?.id)
							}
						/>
					) : null;
				})}
			</>
		);
	};
	const getLastMatchesRender = (matches, firstElement = true) => {
		return (
			<>
				{matches.map((el) => {
					const currentPosition = firstElement ? 0 : el.items.length - 1;
					return el.items?.[currentPosition] ? (
						<NavigationBox
							key={el.items[currentPosition].id}
							dashboard
							label={
								el.items[currentPosition]?.homeTeam +
								" - " +
								el.items[currentPosition]?.awayTeam
							}
							score={el.items[currentPosition]?.results || "---"}
							value={moment(el.items[currentPosition]?.startDate).format(
								"DD/MM/YYYY HH:mm",
							)}
							info={el.items[currentPosition]?.playersCategory}
							dateInfo={moment(el.items[currentPosition]?.startDate).isSame(
								moment(),
								"day",
							)}
							imagePath={el.items[currentPosition]?.matchType}
							onClick={() =>
								navigate("/matches/" + el.items[currentPosition]?.id)
							}
						/>
					) : null;
				})}
			</>
		);
	};
	const getNextTrainings = (trainings, firstElement = true) => {
		return (
			<>
				{trainings.map((el) => {
					const currentPosition = firstElement ? 0 : el.items.length - 1;
					return el.items?.[currentPosition] ? (
						<div
							key={el.items[currentPosition]?.id}
							className="col-6 col-md-4 col-lg-3 no-padding"
						>
							<NavigationBox
								dashboard
								label={moment(el.items[currentPosition]?.startDate).format(
									"DD/MM/YYYY HH:mm",
								)}
								value={el.items[currentPosition]?.fieldName}
								info={el.items[currentPosition]?.playersCategory}
								dateInfo={moment(el.items[currentPosition]?.startDate).isSame(
									moment(),
									"day",
								)}
								onClick={() =>
									navigate("/trainings/" + el.items[currentPosition]?.id)
								}
							/>
						</div>
					) : null;
				})}
			</>
		);
	};

	let boxes = <></>;
	switch (selectedMenuItem) {
		case "manager":
		case "coach":
			boxes = (
				<div className="row no-margin col-container h-100">
					<div className="col-12 col-lg-6 padding-small">
						{nextMatches && managerData ? (
							<div
								className={
									"homepage-block" + (nextMatchesOpened ? " opened" : "closed")
								}
							>
								<VLFCard
									title="Prossime partite"
									content={
										<div className="home-card-container">
											{getNextMatchesRender(nextMatches, false)}
											{managerCoachNextMatchesEmpty && <EmptyData />}
										</div>
									}
									openable={true}
									opened={nextMatchesOpened}
									setOpened={setNextMatchesOpened}
								/>
							</div>
						) : null}
					</div>
					<div className="col-12 col-lg-6 padding-small">
						{lastMatchesResults && managerData ? (
							<div
								className={
									"homepage-block" + (matchesOpened ? " opened" : "closed")
								}
							>
								<VLFCard
									title="Ultimi risultati"
									content={
										<div className="home-card-container">
											{getLastMatchesRender(lastMatchesResults, false)}
											{managerCoachLastMatchesEmpty && <EmptyData />}
										</div>
									}
									openable={true}
									opened={matchesOpened}
									setOpened={setMatchesOpened}
								/>
							</div>
						) : null}
					</div>
					<div className="col-12 padding-small mt-2">
						{nextTrainings && managerData ? (
							<div
								className={
									"homepage-block" +
									(nextTrainingsOpened ? " opened" : "closed")
								}
							>
								<VLFCard
									title="Prossimi allenamenti"
									content={
										<div className="home-card-container small">
											<div className="row no-margin">
												{getNextTrainings(nextTrainings)}
												{managerCoachNextTrainingsEmpty && <EmptyData />}
											</div>
										</div>
									}
									openable={true}
									opened={nextTrainingsOpened}
									setOpened={setNextTrainingsOpened}
								/>
							</div>
						) : null}
					</div>
				</div>
			);
			break;
		case "player":
			boxes = (
				<div className="row no-margin col-container h-100">
					<div className="col-12 col-lg-6 padding-small">
						{nextMatches && managerData ? (
							<div
								className={
									"homepage-block" + (nextMatchesOpened ? " opened" : "closed")
								}
							>
								<VLFCard
									title="Prossime partite"
									content={
										<div className="home-card-container">
											{nextMatches.map((el) => (
												<NavigationBox
													key={el?.id}
													dashboard
													label={el?.homeTeam + " - " + el?.awayTeam}
													value={moment(el?.startDate).format(
														"DD/MM/YYYY HH:mm",
													)}
													info={el?.playersCategory}
													dateInfo={moment(el?.startDate).isSame(
														moment(),
														"day",
													)}
													imagePath={el?.matchType}
													onClick={() => navigate("/matches/" + el?.id)}
												/>
											))}
											{!nextMatches?.length && <EmptyData />}
										</div>
									}
									openable={true}
									opened={nextMatchesOpened}
									setOpened={setNextMatchesOpened}
								/>
							</div>
						) : null}
					</div>
					<div className="col-12 col-lg-6 padding-small">
						{lastMatchesResults && managerData ? (
							<div
								className={
									"homepage-block" + (matchesOpened ? " opened" : "closed")
								}
							>
								<VLFCard
									title="Ultimi risultati"
									content={
										<div className="home-card-container">
											{lastMatchesResults.map((el) => (
												<NavigationBox
													key={el.id}
													dashboard
													label={el?.homeTeam + " - " + el?.awayTeam}
													score={el?.results || "---"}
													value={moment(el?.startDate).format(
														"DD/MM/YYYY HH:mm",
													)}
													info={el?.playersCategory}
													dateInfo={moment(el?.startDate).isSame(
														moment(),
														"day",
													)}
													imagePath={el?.matchType}
													onClick={() => navigate("/matches/" + el?.id)}
												/>
											))}
											{!lastMatchesResults?.length && <EmptyData />}
										</div>
									}
									openable={true}
									opened={matchesOpened}
									setOpened={setMatchesOpened}
								/>
							</div>
						) : null}
					</div>
					<div className="col-12 padding-small mt-2">
						{nextTrainings && managerData ? (
							<div
								className={
									"homepage-block" +
									(nextTrainingsOpened ? " opened" : "closed")
								}
							>
								<VLFCard
									title="Prossimi allenamenti"
									content={
										<div className="home-card-container small">
											<div className="row no-margin">
												{nextTrainings.map((el) => (
													<div
														key={el?.id}
														className="col-6 col-md-4 col-lg-3 no-padding"
													>
														<NavigationBox
															dashboard
															label={moment(el?.startDate).format(
																"DD/MM/YYYY HH:mm",
															)}
															value={el?.fieldName}
															info={el?.playersCategory}
															dateInfo={moment(el?.startDate).isSame(
																moment(),
																"day",
															)}
															onClick={() => navigate("/trainings/" + el?.id)}
														/>
													</div>
												))}
												{!nextTrainings?.length && <EmptyData />}
											</div>
										</div>
									}
									openable={true}
									opened={nextTrainingsOpened}
									setOpened={setNextTrainingsOpened}
								/>
							</div>
						) : null}
					</div>
				</div>
			);
			break;
		case "analyst":
			boxes = (
				<div className="row no-margin col-container h-100">
					<div className="col-12 col-lg-6 padding-small">
						{nextMatches && managerData ? (
							<div
								className={
									"homepage-block" + (nextMatchesOpened ? " opened" : "closed")
								}
							>
								<VLFCard
									title="Prossime partite"
									content={
										<div className="home-card-container">
											{getNextMatchesRender(nextMatches, false)}
											{!nextMatches?.length && <EmptyData />}
										</div>
									}
									openable={true}
									opened={nextMatchesOpened}
									setOpened={setNextMatchesOpened}
								/>
							</div>
						) : null}
					</div>
					<div className="col-12 col-lg-6 padding-small">
						{lastMatchesResults && managerData ? (
							<div
								className={
									"homepage-block" + (matchesOpened ? " opened" : "closed")
								}
							>
								<VLFCard
									title="Ultimi risultati"
									content={
										<div className="home-card-container">
											{getLastMatchesRender(lastMatchesResults, false)}
											{!lastMatchesResults?.length && <EmptyData />}
										</div>
									}
									openable={true}
									opened={matchesOpened}
									setOpened={setMatchesOpened}
								/>
							</div>
						) : null}
					</div>
				</div>
			);
			break;
		case "scout":
			boxes = (
				<div className="row no-margin col-container h-100">
					<div className="col-12 padding-small">
						{scoutData && (
							<VLFCard
								title="Ultimi 10 report inseriti"
								content={
									<div className="row no-margin">
										{reports.length > 0 ? (
											reports
												.sort(
													(a, b) =>
														new Date(b.reportDate) - new Date(a.reportDate),
												)
												.slice(0, 10)
												.map((report) => (
													<div
														key={report.id}
														className="col-12 col-md-6 no-padding"
													>
														<NavigationBox
															dashboard
															label={
																report.prospectPlayer?.firstName +
																" " +
																report.prospectPlayer?.lastName
															}
															value={
																report.prospectTeam?.name +
																(report.playerCategory
																	? " (" + report.playerCategory.name + ")"
																	: "")
															}
															info={report.signalerName}
															imagePath={"Scout"}
															imageLabel={moment(report.reportDate).format(
																"DD/MM/YYYY",
															)}
															onClick={() =>
																navigate("/scout/reports/" + report.id)
															}
														/>
													</div>
												))
										) : (
											<EmptyData />
										)}
									</div>
								}
								openable={true}
							/>
						)}
					</div>
				</div>
			);
			break;
		case "settings":
			boxes = (
				<div className="application-sections-boxes">
					<NavigationBox
						label="Categorie"
						value={settingsData && categories?.length}
						imagePath={require("@/assets/images/manager/category.png")}
						onClick={() => navigate("/settings/categories")}
					/>
					<NavigationBox
						label="Club"
						value={settingsData && clubs?.length}
						imagePath={require("@/assets/images/manager/club.png")}
						onClick={() => navigate("/settings/clubs")}
					/>
					<NavigationBox
						label="Utenti"
						value={settingsData && users?.length}
						imagePath={require("@/assets/images/user.png")}
						onClick={() => navigate("/settings/users")}
					/>
				</div>
			);
			break;
		default:
			break;
	}

	return (
		<Page>
			<Loading visible={loading} />
			<div className="row no-margin homepage-container">
				<div className="col-sm-12 no-padding">{boxes}</div>
			</div>
		</Page>
	);
}

export default HomePage;
