import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";

import {MatchReportAPI} from "@/api/match_report";

import AddIcon from "@mui/icons-material/Add";

import {dateFormat} from "@/utils/constants";

import "./index.scss";

const fieldDateFormat = "YYYY-MM-DD";

const defaultFiltersData = {
	toDate: moment().endOf("month").format(fieldDateFormat),
};

function MatchReportsPage(props) {
	const {fullpage = false, playerId, matchId} = props;

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [matchReports, setMatchReports] = useState([]);

	const [filtersData, setFiltersData] = useState(
		JSON.parse(sessionStorage.getItem("match_reports_filters")) ||
			defaultFiltersData,
	);

	useEffect(() => {
		if (filtersData) {
			sessionStorage.setItem(
				"match_reports_filters",
				JSON.stringify(filtersData),
			);
		}
	}, [filtersData]);

	const filters = [
		{
			key: "fromDate",
			type: "date",
			label: "Da",
			customClassName: "col-6 col-sm-4",
			clearable: false,
			value: filtersData?.fromDate
				? moment(filtersData?.fromDate).format(fieldDateFormat)
				: undefined,
			setValue: (e) => {
				if (e) {
					setFiltersData({
						...filtersData,
						fromDate: moment(e).format(fieldDateFormat),
					});
				}
			},
		},
		{
			key: "toDate",
			type: "date",
			label: "A",
			customClassName: "col-6 col-sm-4",
			clearable: false,
			value: moment(filtersData?.toDate).format(fieldDateFormat),
			setValue: (e) => {
				if (e) {
					setFiltersData({
						...filtersData,
						toDate: moment(e).format(fieldDateFormat),
					});
				}
			},
		},
	];

	const getMatchReports = async () => {
		setLoading(true);
		try {
			const response = await MatchReportAPI.list({playerId, matchId});
			setMatchReports(response);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getMatchReports();
	}, [filtersData, playerId, matchId]);

	const columns = [
		{
			label: "Data",
			key: "reportDate",
			customRender: (item) => {
				return moment(item.reportDate).format(dateFormat);
			},
			small: true,
		},
		{
			label: "Allenatore",
			key: "reportAuthor",
			align: "start",
			medium: true,
		},
	];

	if (playerId || (!playerId && !matchId)) {
		columns.push({
			label: "Partita",
			key: "match",
			align: "start",
			customRender: (item) => {
				return (
					moment(item.match.startDate).format(dateFormat + " HH:mm") +
					" - " +
					item.match.homeTeam +
					" vs " +
					item.match.awayTeam
				);
			},
		});
	}

	if (matchId || (!playerId && !matchId)) {
		columns.push({
			label: "Giocatore",
			key: "player",
			align: "start",
			customRender: (item) => {
				return (
					item.player.firstName +
					" " +
					item.player.lastName +
					" (" +
					item.player.category +
					")"
				);
			},
		});
	}

	columns.push(
		{
			label: "Opinione generale",
			key: "generalOpinion",
			align: "start",
			small: true,
		},
		{
			label: "Valutazione",
			key: "generalRating",
			customRender: (item) => {
				return item.generalRating
					? +parseFloat(item.generalRating).toFixed(2)
					: "---";
			},
			align: "end",
			small: true,
		},
	);

	const listConfiguration = {
		data: matchReports,
		onClick: (report) => {
			navigate("/match-reports/" + report.id);
		},
	};

	const mainActions = [
		{
			icon: <AddIcon />,
			onClick: () => navigate("/match-reports/new"),
		},
	];

	const pageList = (
		<CardsList
			type="match_report"
			breadcrumbs={!playerId && !matchId && fullpage}
			tabbed={playerId}
			fitHeight
			filtersData={!playerId && !matchId ? filters : undefined}
			listConfiguration={listConfiguration}
			defaultSortedField="reportDate"
			defaultSortedOrder="descend"
		/>
	);

	const pageBody = (
		<CardsContainer
			tableComponent={
				<CardsTable
					configurationColumns={columns}
					data={matchReports}
					breadcrumbs={!playerId && !matchId && fullpage}
					fullWidth
					fullHeight={!playerId && !matchId && fullpage}
					filtersData={!playerId && !matchId ? filters : undefined}
					handleClick={(report) => {
						navigate("/match-reports/" + report.id);
					}}
					defaultSortedField="reportDate"
					defaultSortedOrder="descend"
				/>
			}
			listComponent={pageList}
		/>
	);

	if ((playerId || matchId) && !fullpage) {
		return pageBody;
	}

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={mainActions} />} />
			{pageBody}
		</Page>
	);
}

export default MatchReportsPage;
