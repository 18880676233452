import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import Page from "@/components/Page";
import Loading from "@/components/Loading";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";

import {ValuationAPI} from "@/api/valuation";

import AddIcon from "@mui/icons-material/Add";

import {dateFormat, externalValuationOptions} from "@/utils/constants";

import "./index.scss";

const fieldDateFormat = "YYYY-MM-DD";

const defaultFiltersData = {
	toDate: moment().endOf("month").format(fieldDateFormat),
};

function ValuationsPage(props) {
	const {
		fullpage = false,
		external = false,
		prospectPlayerId,
		playerId,
		otherValuations,
	} = props;

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [valuations, setValuations] = useState([]);

	const [filtersData, setFiltersData] = useState(
		JSON.parse(sessionStorage.getItem("valuations_filters")) ||
			defaultFiltersData,
	);

	useEffect(() => {
		if (filtersData) {
			sessionStorage.setItem("valuations_filters", JSON.stringify(filtersData));
		}
	}, [filtersData]);

	const filters = [
		{
			key: "fromDate",
			type: "date",
			label: "Da",
			customClassName: "col-6 col-sm-4",
			clearable: false,
			value: filtersData?.fromDate
				? moment(filtersData?.fromDate).format(fieldDateFormat)
				: undefined,
			setValue: (e) => {
				if (e) {
					setFiltersData({
						...filtersData,
						fromDate: moment(e).format(fieldDateFormat),
					});
				}
			},
		},
		{
			key: "toDate",
			type: "date",
			label: "A",
			customClassName: "col-6 col-sm-4",
			clearable: false,
			value: moment(filtersData?.toDate).format(fieldDateFormat),
			setValue: (e) => {
				if (e) {
					setFiltersData({
						...filtersData,
						toDate: moment(e).format(fieldDateFormat),
					});
				}
			},
		},
	];

	const getValuations = async () => {
		setLoading(true);
		try {
			const response = playerId
				? await ValuationAPI.playerList(playerId)
				: prospectPlayerId
					? await ValuationAPI.prospectPlayerList(prospectPlayerId)
					: await ValuationAPI.list(filtersData);
			setValuations(response);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getValuations();
	}, [filtersData, playerId, prospectPlayerId]);

	const filteredValuations = external
		? valuations.filter((v) => v.valuationType > 1)
		: valuations;

	const mainColumns = [
		{
			label: "Portiere",
			key: "prospectPlayer",
			customRender: (item) => {
				return (
					item?.prospectPlayer?.firstName + " " + item?.prospectPlayer?.lastName
				);
			},
			align: "start",
		},
		{
			label: "Data di nascita",
			key: "dateOfBirth",
			customRender: (item) => {
				return item?.prospectPlayer?.dateOfBirth
					? moment(item?.prospectPlayer?.dateOfBirth).format(dateFormat)
					: "---";
			},
			medium: true,
		},
		{
			label: "N° valutazioni",
			key: "total",
			customRender: (item) => {
				return item?.children?.length;
			},
			align: "end",
			small: true,
		},
	];

	const columns = [
		{
			label: "Data",
			key: "valuationDate",
			customRender: (item, rows) => {
				return moment(item.valuationDate).format(dateFormat);
			},
			medium: true,
		},
		{
			label: "Allenatore",
			key: "valuationAuthor",
			align: "start",
		},
	];

	if (external) {
		columns.push(
			// {
			// 	label: "Portiere",
			// 	key: "prospectPlayer",
			// 	customRender: (item) => {
			// 		return item.prospectPlayer
			// 			? item.prospectPlayer.firstName + " " + item.prospectPlayer.lastName
			// 			: "---";
			// 	},
			// 	align: "start",
			// },
			{
				label: "Tipologia",
				key: "valuationType",
				customRender: (item) => {
					return item.valuationType === 2 || item.valuationType === 4
						? "Partita"
						: "Allenamento";
				},
				medium: true,
				align: "start",
			},
			{
				label: "Squadra",
				key: "prospectTeam",
				customRender: (item) => {
					return item.prospectTeam?.name;
				},
				align: "start",
			},
			{
				label: "Categoria",
				key: "prospectCategory",
				customRender: (item) => {
					return item.playerCategory?.name;
				},
				small: true,
				align: "start",
			},
			{
				label: "Esito",
				key: "finalOvarallRating",
				customRender: (item) => {
					return item.finalOvarallRating !== null &&
						item.finalOvarallRating !== undefined
						? externalValuationOptions.filter(
								(v) => v.value === item.finalOvarallRating.toString(),
							)?.[0]?.label
						: "---";
				},
				medium: true,
			},
		);
	} else {
		columns.push(
			{
				label: "Portiere",
				key: "player",
				customRender: (item) => {
					return item.player.firstName + " " + item.player.lastName;
				},
				align: "start",
			},
			{
				label: "Categoria",
				key: "category",
				customRender: (item) => {
					return item.player.category;
				},
				medium: true,
				align: "start",
			},
			{
				label: "Nel club dal",
				key: "timeInsideClub",
				medium: true,
				align: "end",
			},
		);
	}

	columns.push({
		label: "Media finale",
		key: "finalAverage",
		customRender: (item) => {
			return +parseFloat(item.finalAverage).toFixed(2);
		},
		align: "end",
		medium: true,
	});

	const handleReports = (data) => {
		const newReports = [];
		data.forEach((r) => {
			if (newReports.filter((nr) => nr.id === r.prospectPlayerId)?.length) {
				newReports.map((nr) => {
					if (nr.id === r.prospectPlayerId) {
						nr.children.push(r);
					}
					return nr;
				});
			} else {
				newReports.push({
					id: r.prospectPlayerId,
					prospectPlayer: r.prospectPlayer,
					children: [r],
				});
			}
		});

		return newReports.sort((a, b) => {
			const lastNameA = a.prospectPlayer.lastName.toLowerCase();
			const lastNameB = b.prospectPlayer.lastName.toLowerCase();

			if (lastNameA < lastNameB) return -1;
			if (lastNameA > lastNameB) return 1;
			return 0;
		});
	};

	const listConfiguration = {
		data: filteredValuations,
		onClick: (valuation) =>
			navigate(
				external
					? "/scout/valuations/" + valuation.id
					: "/players-valuations/" + valuation.id + "?playerId=" + playerId,
			),
	};

	const mainActions = [
		// {
		// 	variant: "outlined",
		// 	label: "<< mese",
		// 	onClick: () => {
		// 		setFiltersData({
		// 			...filtersData,
		// 			fromDate: moment(filtersData?.fromDate)
		// 				.subtract(1, "months")
		// 				.startOf("month")
		// 				.format(fieldDateFormat),
		// 			toDate: moment(filtersData?.toDate)
		// 				.subtract(1, "months")
		// 				.endOf("month")
		// 				.format(fieldDateFormat),
		// 		});
		// 	},
		// },
		// {
		// 	variant: "outlined",
		// 	label: "mese >>",
		// 	onClick: () => {
		// 		setFiltersData({
		// 			...filtersData,
		// 			fromDate: moment(filtersData?.fromDate)
		// 				.add(1, "months")
		// 				.format(fieldDateFormat),
		// 			toDate: moment(filtersData?.toDate)
		// 				.add(1, "months")
		// 				.format(fieldDateFormat),
		// 		});
		// 	},
		// },
		{
			icon: <AddIcon />,
			onClick: () =>
				navigate(
					external
						? "/scout/valuations/new"
						: "/players-valuations/new" +
								(playerId ? "?playerId=" + playerId : ""),
				),
		},
	];

	const pageList = (
		<CardsList
			type="valuation"
			breadcrumbs={!playerId && fullpage}
			tabbed={playerId}
			fitHeight
			filtersData={!playerId && !prospectPlayerId ? filters : undefined}
			listConfiguration={listConfiguration}
			defaultSortedField="valuationDate"
			defaultSortedOrder="descend"
		/>
	);

	const getChildRows = (parentRow) => {
		return parentRow.children || [];
	};

	const pageBody = (
		<>
			{/* {!fullpage ? <h6 className="cards-title">Valutazioni interne</h6> : null} */}
			<CardsContainer
				tableComponent={
					<CardsTable
						configurationColumns={external ? mainColumns : columns}
						configurationExpandedColumns={external ? columns : undefined}
						data={
							external ? handleReports(filteredValuations) : filteredValuations
						}
						breadcrumbs={!playerId && fullpage}
						fullWidth
						fullHeight={!playerId && fullpage}
						filtersData={!playerId && !prospectPlayerId ? filters : undefined}
						handleClick={(valuation) => {
							navigate(
								external
									? "/scout/valuations/" + valuation.id
									: "/players-valuations/" +
											valuation.id +
											"?playerId=" +
											playerId,
							);
						}}
						defaultSortedField="valuationDate"
						defaultSortedOrder="descend"
						rowGroupingKey={external ? "id" : undefined}
						getChildRows={external ? getChildRows : undefined}
					/>
				}
				listComponent={pageList}
			/>
			{otherValuations ? <div className="mt-4">{otherValuations}</div> : null}
		</>
	);

	if ((prospectPlayerId || playerId) && !fullpage) {
		return pageBody;
	}

	return (
		<Page>
			<Loading visible={loading} />
			<Breadcrumbs actions={<MainActions actions={mainActions} />} />
			{pageBody}
		</Page>
	);
}

export default ValuationsPage;
