import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import moment from "moment";

import Loading from "@/components/Loading";
import Page from "@/components/Page";
import Breadcrumbs from "@/components/Breadcrumbs";
import MainActions from "@/components/MainActions";
import VLFCard from "@/components/Card";
import ViewField from "@/components/Input/ViewField";
import VLFTextField from "@/components/Input/TextField";
import VLFModal from "@/components/Modal";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {MatchReportAPI} from "@/api/match_report";

import {
	dateFormat,
	ERROR_OPERATION,
	SUCCESSFUL_OPERATION,
} from "@/utils/constants";

import "./index.scss";

function Valuation(props) {
	const {handleNotification} = props;

	// const playerId = new URLSearchParams(useLocation().search).get("playerId");

	const {id} = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [openHandleConfirm, setOpenHandleConfirm] = useState(false);
	const [reportData, setReportData] = useState({});

	const getReport = async () => {
		setLoading(true);
		try {
			const response = await MatchReportAPI.getById(id);
			setReportData(response);
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getReport();
	}, []);

	const deleteReport = async () => {
		setLoading(true);
		try {
			await MatchReportAPI.delete(reportData?.id);
			handleNotification(SUCCESSFUL_OPERATION, "success");
			navigate("/match-reports");
		} catch (error) {
			handleNotification(ERROR_OPERATION, "error");
			setLoading(false);
		} finally {
			setOpenHandleConfirm(false);
		}
	};

	const getReportDescriptions = () => {
		const descriptions = [1, 2, 3, 4, 5, 6].filter((n) => {
			return reportData?.["descriptionGoalConceded" + n]?.length;
		});

		return (
			<div className="d-flex flex-column">
				{descriptions?.map((n, k) => {
					return (
						<div key={k}>
							{k + 1 + " - " + reportData?.["descriptionGoalConceded" + n]}
						</div>
					);
				})}
				{!descriptions?.length ? <div>---</div> : ""}
			</div>
		);
	};

	const reportConfigurationSize = "col-12 col-sm-6 col-lg-4";
	const reportConfiguration = [
		{
			value: reportData?.reportDate
				? moment(reportData?.reportDate).format(dateFormat)
				: null,
			label: "Data",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.reportAuthor,
			label: "Allenatore",
			size: "col-6 col-xl-8",
		},
		{
			value: reportData?.player
				? reportData?.player?.firstName +
					" " +
					reportData?.player?.lastName +
					" (" +
					reportData?.player?.category +
					")"
				: "",
			label: "Portiere",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.match
				? moment(reportData?.match?.startDate).format(dateFormat + " HH:mm") +
					" - " +
					reportData?.match?.homeTeam +
					" vs " +
					reportData?.match?.awayTeam
				: "",
			label: "Partita",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.generalOpinion,
			label: "Opinione",
			size: reportConfigurationSize,
		},
	];

	const matchReportConfiguration = [
		{
			value: reportData?.heatingExecution,
			label: "Esecuzione riscaldamento",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.minutesPlayed
				? reportData?.minutesPlayed + "'"
				: "---",
			label: "Minuti giocati",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.totalGoalsConceded,
			label: "Goal concessi",
			size: reportConfigurationSize,
		},
		{
			value: (
				<div className="d-flex flex-column">
					<div className={"cell" + (reportData?.replaced ? " on" : " off")} />
					{reportData?.reasonReplaced ? (
						<div style={{fontSize: "12px"}}>{reportData?.reasonReplaced}</div>
					) : (
						""
					)}
				</div>
			),
			label: "Sostituito",
			size: "col-12 col-sm-6 col-lg-4",
		},
		{
			value: (
				<div className="d-flex flex-column">
					<div className={"cell" + (reportData?.warned ? " on" : " off")} />
					{reportData?.reasonWarned ? (
						<div style={{fontSize: "12px"}}>{reportData?.reasonWarned}</div>
					) : (
						""
					)}
				</div>
			),
			label: "Ammonito",
			size: "col-12 col-sm-6 col-lg-4",
		},
		{
			value: (
				<div className="d-flex flex-column">
					<div className={"cell" + (reportData?.expelled ? " on" : " off")} />
					{reportData?.reasonExpelled ? (
						<div style={{fontSize: "12px"}}>{reportData?.reasonExpelled}</div>
					) : (
						""
					)}
				</div>
			),
			label: "Espulso",
			size: "col-12 col-sm-6 col-lg-4",
		},
		{
			value: reportData?.note,
			label: "Note",
			size: "col-12 col-sm-6 col-lg-4",
		},
		{
			value: getReportDescriptions(),
			label: "Descrizione goal subiti",
			size: "col-12",
		},
	];

	const cornersInConfiguration = [
		{
			value: reportData?.cornerRetIntervention,
			label: "Uscita del portiere",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.cornerRetSave,
			label: "Parata del portiere (sviluppo diretto)",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.cornerRetDefendedTeammate,
			label: "Palla difesa dai compagni",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.cornerRetOpponents,
			label: "Palla colpita da avversari (no goal)",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.cornerRetGoal,
			label: "Goal (sviluppo diretto)",
			size: reportConfigurationSize,
		},
	];

	const cornersOutConfiguration = [
		{
			value: reportData?.cornerOutIntervention,
			label: "Uscita del portiere",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.cornerOutSave,
			label: "Parata del portiere (sviluppo diretto)",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.cornerOutDefendedTeammate,
			label: "Palla difesa dai compagni",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.cornerOutOpponents,
			label: "Palla colpita da avversari (no goal)",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.cornerOutGoal,
			label: "Goal (sviluppo diretto)",
			size: reportConfigurationSize,
		},
	];

	const statsConfiguration = [
		{
			value: reportData?.manToManMarking,
			label: "Angoli a sfavore con marcatura a uomo",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.zonalMarking,
			label: "Angoli a sfavore con marcatura a zona",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.mixedMarking,
			label: "Angoli a sfavore con marcatura a zona mista",
			size: reportConfigurationSize,
		},

		{
			value: reportData?.blockedCorners,
			label: "Angoli bloccati (uscire e rientrare)",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.shortCorners,
			label: "Angoli battuti corti",
			size: reportConfigurationSize,
		},
		{
			value: reportData?.totalAttacksArea,
			label: "Totale attacchi dell'area",
			size: reportConfigurationSize,
		},
	];

	const generalReportConfiguration = [
		{
			value: (
				<VLFTextField
					value={
						reportData?.reportText?.length
							? reportData.reportText
							: "Nessuna nota inserita"
					}
					size="small"
					multiline
					minRows={3}
					maxRows={3}
					readOnly
				/>
			),
			label: "Relazione generale",
			size: "col-12",
		},
	];

	const getParameterRow = (paramLabel, okLabel, failedLabel) => {
		return (
			<div className="row">
				<div className="col-12 col-lg-6 parameter-container">
					<div className="field-label-container">
						<div className="field-label">{paramLabel}</div>
					</div>
					<div className="parameter-total">
						{parseInt(reportData[okLabel] || 0) +
							parseInt(reportData[failedLabel] || 0)}
					</div>
				</div>
				<div className="col-12 col-md-6 col-lg-3 parameter-container">
					<div className="field-label-container">
						<div className="field-label">Sì</div>
					</div>
					<div className="parameter-total positive">
						{parseInt(reportData[okLabel] || 0)}
					</div>
				</div>
				<div className="col-12 col-md-6 col-lg-3 parameter-container">
					<div className="field-label-container">
						<div className="field-label">No</div>
					</div>
					<div className="parameter-total negative">
						{parseInt(reportData[failedLabel] || 0)}
					</div>
				</div>
			</div>
		);
	};

	const mainActions = [
		{
			icon: <ArrowBackIcon />,
			title: "Indietro",
			onClick: () => navigate(-1),
			color: "info",
		},
		{
			icon: <EditIcon />,
			title: "Modifica",
			onClick: () => navigate("/match-reports/" + reportData?.id + "/edit"),
		},
		{
			icon: <DeleteIcon />,
			title: "Elimina",
			onClick: () => setOpenHandleConfirm(true),
			color: "error",
		},
	];

	const breadcrumbsElements = [
		{
			label: "Report gara",
			path: "/match-reports",
		},
		{label: "/"},
	];

	return (
		<Page className="player-container">
			<Loading visible={loading} />
			<Breadcrumbs
				elements={breadcrumbsElements}
				current={
					reportData?.match
						? moment(reportData?.match?.startDate).format(
								dateFormat + " HH:mm",
							) +
							" - " +
							reportData?.match?.homeTeam +
							" vs " +
							reportData?.match?.awayTeam
						: ""
				}
				actions={<MainActions actions={mainActions} />}
			/>
			<div className="sheet-container">
				<div className="row no-margin col-container">
					<div className="col-sm-12 col-lg-12 padding-small">
						<VLFCard
							title="Informazioni report"
							content={
								<div className="row">
									<div className="col-sm-12 col-lg-10">
										<ViewField configuration={reportConfiguration} />
									</div>
									<div className="col-sm-12 col-lg-2">
										<div
											className="d-flex flex-column align-items-center gap-2 h-100"
											style={{borderLeft: "1px solid #ebf1fd"}}
										>
											<div className="view-field-container avg h-100 justify-content-center">
												<div className="field-label-container justify-content-center">
													<div className="field-label">Valutazione</div>
												</div>
												<div className="stat-circle-container">
													<div className="stat-container">
														<div className="value">
															{
																+parseFloat(
																	reportData?.generalRating || 0,
																).toFixed(2)
															}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							}
							openable={false}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni partita"
							content={
								<div className="row">
									<div className="col-sm-12">
										<ViewField configuration={matchReportConfiguration} />
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Informazioni valutazione"
							content={
								<div className="row no-margin parameters-area">
									<div className="col-12">
										<div className="row">
											<div className="col-12 col-lg-6 col-xl-3 parameter-container">
												<div className="field-label-container">
													<div className="field-label">
														{"Totale offensivo"}
													</div>
												</div>
												<div className="parameter-total">
													{reportData?.totalOffensiveGameOk +
														reportData?.totalOffensiveGameFailed}
												</div>
											</div>
											<div className="col-12 col-lg-6 col-xl-3 parameter-container">
												<div className="field-label-container">
													<div className="field-label">
														{"Totale difensivo"}
													</div>
												</div>
												<div className="parameter-total">
													{reportData?.totalDefensiveGameOk +
														reportData?.totalDefensiveGameFailed}
												</div>
											</div>
											<div className="col-12 col-lg-6 col-xl-3 parameter-container">
												<div className="field-label-container">
													<div className="field-label">{"Volume totale"}</div>
												</div>
												<div className="parameter-total">
													{reportData?.totalOffensiveGameOk +
														reportData?.totalOffensiveGameFailed +
														reportData?.totalDefensiveGameOk +
														reportData?.totalDefensiveGameFailed}
												</div>
											</div>
											<div className="col-12 col-lg-6 col-xl-3 parameter-container">
												<div className="field-label-container">
													<div className="field-label">
														{"Totale angoli subiti"}
													</div>
												</div>
												<div className="parameter-total">
													{reportData?.totalCorners}
												</div>
											</div>
										</div>
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Gioco offensivo"
							content={
								<div className="row parameters-area">
									<div className="col-12 col-xl-3">
										<div className="parameters-image">
											<img
												src={require("@/assets/images/manager/pass-soccer-ball.png")}
												alt=""
											/>
										</div>
									</div>
									<div className="col-12 col-xl-9">
										{getParameterRow(
											"Passaggio corto",
											"shortPassOk",
											"shortPassFailed",
										)}
										{getParameterRow(
											"Lancio medio e lungo",
											"longMidThrowOk",
											"longMidThrowFailed",
										)}
										{getParameterRow(
											"Ripartenza con le mani",
											"restartWithHandsOk",
											"restartWithHandsFailed",
										)}
										{getParameterRow(
											"Ripartenza con rinvio al volo",
											"remittalOnTheFlyOk",
											"remittalOnTheFlyFailed",
										)}
										{getParameterRow(
											"Transizione positiva fuori area",
											"positiveTransitionOutsideOk",
											"positiveTransitionOutsideFailed",
										)}
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Gioco difensivo"
							content={
								<div className="row parameters-area">
									<div className="col-12 col-xl-3">
										<div className="parameters-image">
											<img
												src={require("@/assets/images/manager/hockey-goalpost.png")}
												alt=""
											/>
										</div>
									</div>
									<div className="col-12 col-xl-9">
										{getParameterRow(
											"Parata in presa",
											"saveGripOk",
											"saveGripFailed",
										)}
										{getParameterRow(
											"Parata in respinta/deviazione",
											"saveReboundOk",
											"saveReboundFailed",
										)}
										{getParameterRow(
											"Uscita alta presa",
											"highClaimOk",
											"highClaimFailed",
										)}
										{getParameterRow(
											"Uscita alta deviazione/respinta",
											"highPunchOk",
											"highPunchFailed",
										)}
										{getParameterRow(
											"Uscita bassa/attacco palla",
											"lowDiveOk",
											"lowDiveFailed",
										)}
										{getParameterRow(
											"Copertura profondità area",
											"depthCoverageInsideOk",
											"depthCoverageInsideFailed",
										)}
										{getParameterRow(
											"Copertura profondità fuori area",
											"depthCoverageOutsideOk",
											"depthCoverageOutsideFailed",
										)}
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Calci d'angolo ad uscire"
							content={
								<div className="row">
									<div className="col-sm-12">
										<ViewField configuration={cornersOutConfiguration} />
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Calci d'angolo a rientrare"
							content={
								<div className="row">
									<div className="col-sm-12">
										<ViewField configuration={cornersInConfiguration} />
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Statistiche varie"
							content={
								<div className="row">
									<div className="col-sm-12">
										<ViewField configuration={statsConfiguration} />
									</div>
								</div>
							}
						/>
					</div>
				</div>
				<div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Generali"
							content={
								<div className="row">
									<div className="col-sm-12">
										<ViewField configuration={generalReportConfiguration} />
									</div>
								</div>
							}
						/>
					</div>
				</div>
				{/* <div className="row no-margin mt-2">
					<div className="col-sm-12 padding-small">
						<VLFCard
							title="Dettagli"
							content={
								<div className="row">
									<div className="col-sm-12">
										<ViewField configuration={reportDetailsConfiguration} />
									</div>
								</div>
							}
						/>
					</div>
				</div> */}
			</div>
			<VLFModal
				open={openHandleConfirm}
				title="Eliminazione"
				content={
					<div className="mt-2">
						Sei sicuro di voler eliminare questo elemento?
					</div>
				}
				confirm={() => deleteReport()}
				close={() => setOpenHandleConfirm(false)}
				confirmLabel="Conferma"
			/>
		</Page>
	);
}

export default Valuation;
