import * as React from "react";
import moment from "moment";

import DoneIcon from "@mui/icons-material/Done";

import "./index.scss";

export default function RosterPlayerCard(props) {
	const {element, internal, selected} = props;

	const rosterPlayer = element?.player || element?.prospectPlayer || element;

	return (
		<div className="roster-player-card-container">
			<div className="roster-player-card-header">
				<div className="top-left">
					{internal ? (
						<img src={require("@/assets/images/old/logo_player.png")} alt="" />
					) : (
						""
					)}
				</div>
				<div className="top-right">
					{selected ? <DoneIcon color="#002160" /> : ""}
				</div>
			</div>
			<div className="roster-player-card-info">
				<div className="info-title">
					<img src={require("@/assets/images/manager/player.png")} alt="" />
					<div className="d-flex flex-column">
						<div style={{lineHeight: 1, fontWeight: "bold"}}>
							{rosterPlayer.firstName + " " + rosterPlayer.lastName}
						</div>
						<div>
							{rosterPlayer.dateOfBirth
								? moment(rosterPlayer.dateOfBirth).format("YYYY")
								: "---"}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
