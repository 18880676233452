import React, {useEffect, useState} from "react";
import moment from "moment";

import VLFModal from "@/components/Modal";
import VLFTextField from "@/components/Input/TextField";
import VLFSelectNew from "@/components/Input/SelectNew";
import CardsList from "@/components/CardsList";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import {ProspectAPI} from "@/api/prospect";

import {REQUIRED_FIELD} from "@/utils/constants";
import {checkRequiredFields} from "@/utils/functions";
import {useCategories, usePlayers} from "@/utils/hooks";

import "./index.scss";

const requiredFields = ["name", "category"];

const birthYears = [
	{value: 2006, label: "2006"},
	{value: 2007, label: "2007"},
	{value: 2008, label: "2008"},
	{value: 2009, label: "2009"},
	{value: 2010, label: "2010"},
	{value: 2011, label: "2011"},
	{value: 2012, label: "2012"},
	{value: 2013, label: "2013"},
	{value: 2014, label: "2014"},
	{value: 2015, label: "2015"},
	{value: 2016, label: "2016"},
	{value: 2017, label: "2017"},
	{value: 2018, label: "2018"},
];

function HandleRosterModal(props) {
	const {open, initialData, confirm, close} = props;

	const [formData, setFormData] = useState({
		players: [],
	});
	const [playersConfiguration, setPlayersConfiguration] = useState();
	const [errors, setErrors] = useState([]);
	const [loading, setLoading] = useState(false);

	const [searched, setSearched] = useState("");
	const [birthYear, setBirthYear] = useState();
	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
		formatted: true,
		filtered: true,
	});
	const {players} = usePlayers({
		setLoading: false,
		handleNotification: undefined,
	});
	const [prospectPlayers, setProspectPlayers] = useState([]);
	const getProspectPlayers = async () => {
		setLoading(true);
		try {
			const response = await ProspectAPI.playersList();
			setProspectPlayers(response);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getProspectPlayers();
	}, []);

	useEffect(() => {
		if (initialData) {
			const selectedPlayers = initialData.players?.map((p) => {
				return {
					id: p.player.id,
					internal: p.player.membershipDate !== undefined,
				};
			});

			const selectedCategory = {
				value: initialData?.players?.[0]?.playersCategory.id,
				label: initialData?.players?.[0]?.playersCategory.name,
			};

			setFormData({
				...initialData,
				category: selectedCategory,
				players: selectedPlayers,
			});
		}
	}, [initialData]);

	useEffect(() => {
		if (!open) {
			setFormData({players: []});
		}
	}, [open]);

	const allPlayers = players?.concat?.(prospectPlayers);
	let filteredPlayers = searched
		? allPlayers?.filter((p) => {
				return (
					p.firstName.toLowerCase()?.indexOf(searched.toLowerCase()) !== -1 ||
					p.lastName.toLowerCase()?.indexOf(searched.toLowerCase()) !== -1
				);
			})
		: allPlayers;
	filteredPlayers = birthYear
		? filteredPlayers.filter((p) => {
				return (
					p.dateOfBirth &&
					moment(p.dateOfBirth).format("YYYY") === birthYear?.label
				);
			})
		: filteredPlayers;

	useEffect(() => {
		setPlayersConfiguration({
			data: filteredPlayers,
			onClick: (player) => {
				let updatedPlayers = Array.from(formData.players || []);
				if (
					!formData?.players ||
					formData?.players?.filter((p) => p.id === player.id)?.length === 0
				) {
					if (!formData?.players || formData?.players?.length < 3) {
						updatedPlayers.push({
							id: player.id,
							internal: player.membershipDate !== undefined,
						});
						setFormData({...formData, players: updatedPlayers});
					}
				} else {
					const index = formData.players.findIndex((p) => p.id === player.id);
					updatedPlayers.splice(index, 1);
					if (index > -1) {
						setFormData({
							...formData,
							players: updatedPlayers,
						});
					}
				}
			},
			selected: (element) =>
				formData?.players &&
				formData.players.filter((p) => p.id === element.id)?.length,
			internal: (element) => element.membershipDate !== undefined,
			separator: false,
		});
	}, [searched, birthYear, formData, players, prospectPlayers]);

	const selectedPlayersConfiguration = allPlayers
		.filter(
			(p) =>
				formData?.players &&
				formData.players.filter((p2) => p2.id === p.id)?.length,
		)
		?.map((p) => p.firstName + " " + p.lastName);

	return (
		<VLFModal
			maxWidth="md"
			open={open}
			title={initialData ? "Modifica roster" : "Aggiungi roster"}
			close={close}
			confirm={() => {
				const errors = checkRequiredFields(requiredFields, formData);
				setErrors(errors);

				if (formData?.players?.length === 0) {
					setErrors([...errors, "players"]);
				}

				if (!errors.length) {
					confirm(formData, initialData?.id);
				}
			}}
			content={
				<>
					<div className="row">
						<div className="col-12 col-sm-6">
							<VLFTextField
								name="name"
								value={formData?.name || ""}
								onChange={(event) => {
									setErrors([]);
									setFormData({...formData, name: event.target.value});
								}}
								label="Nome"
								required
								size="small"
								helperText={errors.indexOf("name") !== -1 ? REQUIRED_FIELD : ""}
								error={errors.indexOf("name") !== -1}
							/>
						</div>
						<div className="col-12 col-sm-6">
							<VLFSelectNew
								options={categories}
								sortOrder="descend"
								value={formData?.category}
								onChange={(newValue) => {
									setErrors([]);
									setFormData({...formData, category: newValue});
								}}
								label="Categoria"
								required
								size="small"
								helperText={
									errors.indexOf("category") !== -1 ? REQUIRED_FIELD : ""
								}
								error={errors.indexOf("category") !== -1}
							/>
						</div>
					</div>
					<div style={{fontWeight: "bold"}}>Portieri selezionati</div>
					<div className="mb-2">
						{selectedPlayersConfiguration?.length
							? selectedPlayersConfiguration.join(" - ")
							: "---"}
					</div>
					<div style={{fontWeight: "bold"}}>Seleziona portieri</div>
					<div className="players-filters-container">
						<div className="players-filter">
							<VLFTextField
								name="searched"
								value={searched || ""}
								onChange={(event) => {
									setSearched(event.target.value);
								}}
								placeholder="Cerca..."
								size="small"
							/>
						</div>
						<div className="players-filter">
							<VLFSelectNew
								options={birthYears}
								clearable
								value={birthYear}
								onChange={(newValue) => {
									setBirthYear(newValue);
								}}
								placeholder="Anno di nascita"
							/>
						</div>
					</div>
					<div
						style={{maxHeight: "200px", overflowX: "hidden", overflowY: "auto"}}
					>
						{loading ? (
							<Box sx={{display: "flex", justifyContent: "center"}}>
								<CircularProgress />
							</Box>
						) : (
							<CardsList
								type="roster_player"
								listConfiguration={playersConfiguration}
								defaultSortedField="lastName"
							/>
						)}
					</div>
				</>
			}
		/>
	);
}

export default HandleRosterModal;
