import * as React from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";

import CardsContainer from "@/components/CardsContainer";
import CardsList from "@/components/CardsList";
import CardsTable from "@/components/CardsTable";

import {dateFormat, valuationAnalysisOption} from "@/utils/constants";
import {useCategories} from "@/utils/hooks";

import "./index.scss";

export default function ScoutReportsContainer(props) {
	const {
		reports,
		filters,
		fullHeight = true,
		grouped = true,
		breadcrumbs = true,
		expanded = false,
	} = props;

	const navigate = useNavigate();

	const {categories} = useCategories({
		setLoading: false,
		handleNotification: undefined,
	});

	const getAlertSeverity = (valuation) => {
		switch (valuation) {
			case 0:
				return {severity: "success", message: "Idoneo", value: "Idoneo"};
			case 1:
				return {severity: "error", message: "Non Idoneo", value: "Non Idoneo"};
			case 2:
				return {
					severity: "warning",
					message: "Da seguire",
					value: "Da seguire",
				};
			case -1:
			default:
				return {severity: "info", message: "Nessuna valutazione", value: "N/A"};
		}
	};

	const sortReports = (data) => {
		return data.sort((a, b) => {
			const dateA = new Date(a.prospectPlayer?.dateOfBirth);
			const dateB = new Date(b.prospectPlayer?.dateOfBirth);

			if (a.valuation === 1 && b.valuation !== 1) return 1;
			if (a.valuation !== 1 && b.valuation === 1) return -1;

			return dateB - dateA;
		});
	};

	const handleReports = (data) => {
		const newReports = [];
		data.forEach((r) => {
			if (newReports.filter((nr) => nr.id === r.prospectPlayerId)?.length) {
				newReports.map((nr) => {
					if (nr.id === r.prospectPlayerId) {
						nr.children.push(r);
					}
					return nr;
				});
			} else {
				newReports.push({
					id: r.prospectPlayerId,
					prospectPlayer: r.prospectPlayer,
					children: [r],
				});
			}
		});

		return newReports;
	};

	const columns = [
		{
			label: "Data",
			key: "reportDate",
			customRender: (item) => {
				console.log("item", item);
				return moment(item.reportDate).format(dateFormat);
			},
			medium: true,
		},
		{
			label: "Segnalatore",
			key: "signalerName",
			align: "start",
			medium: true,
		},
		{
			label: "Portiere",
			key: "firstName",
			customRender: (item) => {
				return (
					item?.prospectPlayer?.firstName + " " + item?.prospectPlayer?.lastName
				);
			},
			align: "start",
			hidden: expanded,
		},
		{
			label: "Data di nascita",
			key: "dateOfBirth",
			customRender: (item) => {
				return item?.prospectPlayer?.dateOfBirth
					? moment(item?.prospectPlayer?.dateOfBirth).format(dateFormat)
					: "---";
			},
			medium: true,
			hidden: expanded,
		},
		{
			label: "Squadra",
			key: "teamName",
			customRender: (item) => {
				return item?.prospectTeam?.name;
			},
			align: "start",
		},
		{
			label: "Valutazione",
			key: "valuation",
			customRender: (item) => {
				const alertData = getAlertSeverity(item.valuation) || {
					severity: "",
					message: "",
				};
				return (
					<div className={"severity " + (alertData.severity || "")}>
						{alertData.message || "---"}
					</div>
				);
			},
		},
		{
			label: "Approfondimento",
			key: "deepening",
			customRender: (item) => {
				const deepening = item?.deepening?.split(",");
				return (
					<div className="d-flex flex-column gap-1">
						{deepening
							?.filter((d) => d)
							?.map((d, k) => {
								const current = valuationAnalysisOption.filter(
									(o) => o.value === d,
								)?.[0];
								return (
									<div
										key={k}
										className={"severity mx-1" + (current ? " deepening" : "")}
									>
										{current?.label || "---"}
									</div>
								);
							})}
						{!deepening ? "---" : ""}
					</div>
				);
			},
		},
	];

	const mainColumns = [
		{
			label: "Portiere",
			key: "firstName",
			customRender: (item) => {
				return (
					item?.prospectPlayer?.firstName + " " + item?.prospectPlayer?.lastName
				);
			},
			align: "start",
		},
		{
			label: "Data di nascita",
			key: "dateOfBirth",
			customRender: (item) => {
				return item?.prospectPlayer?.dateOfBirth
					? moment(item?.prospectPlayer?.dateOfBirth).format(dateFormat)
					: "---";
			},
			medium: true,
		},
	];

	const groupedReports = {};
	categories
		.filter((c) => c.name !== "Prima Squadra")
		.sort((a, b) => a.name?.localeCompare(b.name))
		.forEach((c) => {
			const currentCategory = c.name;
			const members = reports?.filter(
				(r) => r?.playerCategory?.name === currentCategory,
			);
			groupedReports[currentCategory] = sortReports(members);
		});

	const listConfiguration = {
		data: grouped ? groupedReports : sortReports(reports),
		onClick: (report) => navigate("/scout/reports/" + report.id),
	};

	const getChildRows = (parentRow) => {
		return parentRow.children || [];
	};

	return (
		<CardsContainer
			tableComponent={
				<CardsTable
					breadcrumbs={breadcrumbs}
					fullWidth
					fullHeight={fullHeight}
					middleHeight={!fullHeight}
					filtersData={filters || undefined}
					configurationColumns={expanded ? mainColumns : columns}
					configurationExpandedColumns={expanded ? columns : undefined}
					data={
						expanded
							? handleReports(sortReports(reports))
							: sortReports(reports)
					}
					handleClick={(report) => {
						navigate("/scout/reports/" + report.id);
					}}
					rowGroupingKey={expanded ? "id" : undefined}
					getChildRows={expanded ? getChildRows : undefined}
				/>
			}
			listComponent={
				<CardsList
					type="report"
					breadcrumbs={breadcrumbs}
					grouped={grouped}
					filtersData={filters || undefined}
					listConfiguration={listConfiguration}
				/>
			}
		/>
	);
}
